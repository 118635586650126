import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import clsx from 'clsx';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { operariosProvider, tareasProvider } from '../../api';
import { getTiempoTotal } from '../../api/tareas-functions';
import useAuthState from '../../contexts/AuthState';
import useSplash from '../../contexts/SplashState';
import { formatDate, formatISODate } from '../../utils';
import Button from '../common/Button';
import { PageBodySection } from '../common/PageBodySection';
import { BaseSelectOperarioView } from './editar/SelectOperarioView';
import MarcajesList from './marcajes/MarcajesList';
import LabelChip from './notas/LabelChip';
import { TimeSlotItem } from './TimeSlotItem';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
        },
        subtitle: {
            fontSize: 14,
            color: 'white',
            margin: theme.spacing(2, 3),
            textAlign: 'center',
        },
        jornadaVacia: {
            color: '#8F95AF',
        },
        jornadaTrabajada: {
            '& button': {
                backgroundColor: '#DADEE9',
                color: '#8F95AF',
            },
            '& button:hover': {
                backgroundColor: '#DADEE9',
                color: '#8F95AF',
            },
        },
        noLaborable: {
            '& button': {
                border: '1px solid #EA5759',
            },
        },
        selectedDate: {
            '& button': {
                border: '2px solid #45486E',
            },
        },
        jornadaBullet: {
            backgroundColor: '#DADEE9',
            width: 12,
            height: 12,
            borderRadius: 4,
            marginRight: theme.spacing(1),
        },
        noLaborableBullet: {
            backgroundColor: 'transparent',
            border: '1px solid #EA5759',
            boxSizing: 'border-box',
        },
        infoPaper: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${theme.spacing(1.5)}px`,
            '&>div': {
                display: 'flex',
                alignItems: 'center',
                fontSize: 12,
                color: '#213061',
            },
            marginBottom: theme.spacing(3),
        },
        squareButton: {
            marginLeft: 8,
            height: '100%',
            minWidth: 44,
            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
            '&.facturada': {
                opacity: 0.4,
            },
        },
        hoyTag: {
            background: 'linear-gradient(180deg, #4D62FF 0%, #4D96FF 100%), #EDF8F5',
            borderRadius: 20,
            color: 'white',
            width: 40,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 11,
            marginTop: -2,
            marginBottom: -2,
        },
        emptyElement: {
            borderRadius: 4,
            border: '1px dashed #BABABA',
            minHeight: 38,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            color: '#818CAE',
            flex: 1,
        },
        operarioName: {
            fontWeight: 'normal',
            color: theme.palette.neutral.grey4,
        },
        addButton: {
            marginLeft: 'auto',
            padding: theme.spacing(1, 1.5),
        },
    }),
    { name: 'JornadasList' },
);

const materialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body2: {
                fontFamily: 'inherit',
                fontWeight: 'inherit',
            },
        },
        MuiButtonBase: {
            root: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 13,
            },
        },
        MuiPickersBasePicker: {
            container: {
                alignItems: 'center',
            },
            pickerView: {
                minHeight: 340,
                justifyContent: 'flex-start',
                width: '100%',
            },
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                flex: 1,
                backgroundColor: 'transparent',
                overflow: 'initial',
            },
        },
        MuiPickersCalendar: {
            week: {
                justifyContent: 'space-between',
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: '#213061',
                backgroundColor: 'transparent',
            },
            transitionContainer: {
                textTransform: 'capitalize',
                color: '#213061',
            },
            dayLabel: {
                color: '#213061',
                textTransform: 'uppercase',
                fontWeight: 600,
            },
            daysHeader: {
                justifyContent: 'space-between',
            },
        },
        MuiPickersDay: {
            day: {
                color: 'inherit',
                fontWeight: 'normal',
                borderRadius: 8,
                margin: 2,
            },
            daySelected: {
                backgroundColor: 'transparent',
                color: 'inherit',
                fontWeight: 'normal',
            },
            current: {
                fontWeight: 700,
                backgroundColor: 'inherit',
                color: '#45486E',
                // border: '2px solid #45486E',
                '&>span:first-of-type': {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '&>span:first-of-type::before': {
                    content: '"HOY"',
                    fontSize: 8,
                    marginTop: 3,
                    marginBottom: -3,
                },
            },
        },
    },
});

export const JornadasList = ({ id, tarea, jornadas, editable, selectedJornadaDate, refresh }) => {
    const thisMonth = new Date(selectedJornadaDate);
    thisMonth.setDate(1);

    const [currentMonth, setCurrentMonth] = useState(thisMonth);
    const [noLaborables, setNoLaborables] = useState([]);
    const [deletingJornadaId, setDeletingJornadaId] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const currentYear = currentMonth.getFullYear();
    const { showCustomComponent } = useSplash();
    const {
        userInfo: {
            preferencias: { add_tiempo_operarios: addTiempoOperarios },
        },
    } = useAuthState();

    useEffect(() => {
        operariosProvider.getNoLaborablesYear(currentYear).then(setNoLaborables);
    }, [currentYear]);

    const jornadasByFecha = useMemo(() => {
        const jornadasByFecha = {};
        tarea.jornadas
            .filter((j) => addTiempoOperarios || j.propia)
            .forEach((jornada) => {
                if (!jornadasByFecha[jornada.fecha]) jornadasByFecha[jornada.fecha] = [];

                jornadasByFecha[jornada.fecha].push(jornada);
            });
        return jornadasByFecha;
    }, [tarea.jornadas]);

    const selectedJornadas = useMemo(
        () => jornadasByFecha[selectedJornadaDate] ?? [],
        [jornadasByFecha, selectedJornadaDate],
    );

    const labelJornada = useMemo(
        () =>
            selectedJornadaDate ? (
                isToday(new Date(selectedJornadaDate)) ? (
                    <div className={classes.hoyTag}>HOY</div>
                ) : (
                    formatDate(new Date(selectedJornadaDate))
                )
            ) : null,
        [selectedJornadaDate, classes],
    );

    const tiempoAcumulado = useMemo(() => getTiempoTotal(tarea), [tarea]);
    const selectedIsToday = !selectedJornadaDate || isToday(new Date(selectedJornadaDate));
    const isFinalizada = tarea.estado === 'FINALIZADA';

    const operariosOptions = tarea.operarios
        .filter((op) => !selectedJornadas.some((j) => j.operario_id === op.id))
        .map((op) => ({ id: op.id, nombre: op.nombre }));

    function showAddTiempoOperarios() {
        showCustomComponent(({ closeSplash }) => (
            <BaseSelectOperarioView
                setFieldValue={(selectedOperarios) => {
                    history.push(`/tarea/${id}/tiempo/${selectedJornadaDate}`, {
                        operarios: selectedOperarios,
                    });
                    closeSplash();
                }}
                onClose={closeSplash}
                operarios={operariosOptions}
            />
        ));
    }

    return (
        <PageBodySection>
            <ThemeProvider theme={materialTheme}>
                <DatePicker
                    disableToolbar={true}
                    autoOk
                    openTo='date'
                    variant='static'
                    value={currentMonth}
                    onChange={(ev) => {
                        history.replace(`/tarea/${id}/jornadas/${formatISODate(ev)}`);
                    }}
                    onMonthChange={setCurrentMonth}
                    renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                        const isoDay = formatISODate(day);
                        const tiempoJornada = jornadasByFecha[isoDay];
                        const jornadaClass = tiempoJornada ? classes.jornadaTrabajada : classes.jornadaVacia;

                        return (
                            <span
                                className={classNames(jornadaClass, {
                                    [classes.selectedDate]: selectedJornadaDate === isoDay,
                                    [classes.noLaborable]: noLaborables.includes(isoDay),
                                })}
                            >
                                {dayComponent}
                            </span>
                        );
                    }}
                />
            </ThemeProvider>

            <div className={classes.infoPaper}>
                <div>
                    <span className={classes.jornadaBullet} /> Tienes horas registradas
                </div>
                <div>
                    <span className={classNames(classes.jornadaBullet, classes.noLaborableBullet)} /> No laborable
                </div>
            </div>

            {addTiempoOperarios ? (
                <>
                    <Typography variant='h4' className={classes.title}>
                        Mano de obra
                        {selectedJornadas.length === 1 &&
                            (selectedJornadas[0].facturada || selectedJornadas[0].revisada) && (
                                <LabelChip
                                    label={selectedJornadas[0].facturada ? 'Facturado' : 'Revisado'}
                                    icon={<LockIcon />}
                                />
                            )}
                    </Typography>
                    {selectedJornadas.length > 0 ? (
                        selectedJornadas.map((selectedJornada) => {
                            const bloqueada = selectedJornada.facturada || selectedJornada.revisada;
                            return (
                                <div key={selectedJornada.id}>
                                    {addTiempoOperarios && (
                                        <Typography variant='body2' className={classes.operarioName}>
                                            {selectedJornada.propia ? 'Tu' : selectedJornada.operario}
                                            {bloqueada && (
                                                <LabelChip
                                                    label={selectedJornada.facturada ? 'Facturado' : 'Revisado'}
                                                    icon={<LockIcon />}
                                                />
                                            )}
                                        </Typography>
                                    )}
                                    <TimeSlotItem
                                        label={labelJornada}
                                        tiempo={selectedJornada.tiempo}
                                        button={
                                            editable &&
                                            !bloqueada && (
                                                <>
                                                    <MuiButton
                                                        disabled={selectedJornada.facturada}
                                                        onClick={() =>
                                                            history.push(
                                                                `/tarea/${id}/tiempo-jornada/${selectedJornada.id}`,
                                                                {
                                                                    editing: true,
                                                                },
                                                            )
                                                        }
                                                        className={clsx(
                                                            classes.squareButton,
                                                            selectedJornada.facturada ? 'facturada' : null,
                                                        )}
                                                    >
                                                        <EditIcon style={{ color: 'white', fontSize: 20 }} />
                                                    </MuiButton>
                                                    <MuiButton
                                                        disabled={selectedJornada.facturada}
                                                        onClick={() => setDeletingJornadaId(selectedJornada.id)}
                                                        className={clsx(
                                                            classes.squareButton,
                                                            selectedJornada.facturada ? 'facturada' : null,
                                                        )}
                                                    >
                                                        <DeleteIcon style={{ color: 'white', fontSize: 20 }} />
                                                    </MuiButton>
                                                </>
                                            )
                                        }
                                        style={{ marginBottom: 12 }}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 12,
                            }}
                        >
                            <div className={classes.emptyElement}>
                                No hay registro de tiempo para {selectedIsToday ? 'hoy' : 'esta fecha'}
                            </div>
                            {editable && addTiempoOperarios && (
                                <MuiButton
                                    onClick={() =>
                                        addTiempoOperarios
                                            ? showAddTiempoOperarios()
                                            : history.push(`/tarea/${id}/tiempo/${selectedJornadaDate}`)
                                    }
                                    style={{
                                        marginLeft: 8,
                                        minWidth: 44,
                                        height: 40,
                                        background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
                                    }}
                                >
                                    <AddIcon style={{ color: 'white', fontSize: 20 }} />
                                </MuiButton>
                            )}
                        </div>
                    )}
                    {addTiempoOperarios && editable && operariosOptions.length > 0 && selectedJornadas.length > 0 && (
                        <div style={{ display: 'flex' }}>
                            <Button
                                disabled={isFinalizada}
                                startIcon={<AddIcon />}
                                color='primary'
                                className={classes.addButton}
                                onClick={() => {
                                    showAddTiempoOperarios();
                                }}
                            >
                                Añadir operarios
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Typography variant='h4' className={classes.title}>
                        Mano de obra
                    </Typography>
                    <MarcajesList selectedJornadaDate={selectedJornadaDate} tiempoAcumulado={tiempoAcumulado} />
                </>
            )}
            <Dialog open={Boolean(deletingJornadaId)}>
                <DialogTitle
                    disableTypography
                    style={{
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600,
                    }}
                >
                    ¿Estás seguro que quieres borrar esta jornada?
                </DialogTitle>
                <DialogContent
                    style={{
                        color: '#818CAE',
                        fontSize: 13,
                        textAlign: 'center',
                    }}
                >
                    Si hay materiales asociados también se borrarán. Esta operación no se puede deshacer.
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between', padding: 20 }}>
                    <MuiButton onClick={() => setDeletingJornadaId(false)}>Cancelar</MuiButton>
                    <MuiButton
                        onClick={() => {
                            tareasProvider
                                .actionOnId(id, `tiempo-jornada/${deletingJornadaId}`, null, { method: 'delete' })
                                .then((res) => {
                                    refresh();
                                    setDeletingJornadaId(false);
                                });
                        }}
                        variant='contained'
                        color='primary'
                        autoFocus
                    >
                        Borrar
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </PageBodySection>
    );
};

JornadasList.propTypes = {
    editable: PropTypes.any,
    id: PropTypes.any,
    jornadas: PropTypes.any,
    refresh: PropTypes.any,
    selectedJornadaDate: PropTypes.any,
    tarea: PropTypes.any,
};
