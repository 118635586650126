import DataProvider from './DataProvider';

export const empty = {
    year: '',
    dates: '',
    nombre: '',
    por_defecto: false,
};

class CalendariosProvider extends DataProvider {
    constructor() {
        super('calendarios', empty);
    }

    getNoLaborablesYear = async (year) => this.getAll(`no_laborables/${year}`);
}

export const dataProvider = new CalendariosProvider();
