import { IconButton } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import isWithinInterval from 'date-fns/isWithinInterval';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { operariosProvider, solicitudesProvider } from '../../api';
import { convertDates } from '../../api/solicitudes';
import { formatISODate } from '../../utils';
import { PageBody } from '../common/PageBody';
import { PageHeader } from '../common/PageHeader';
import LeyendaCalendario from './LeyendaCalendario';
import SolicitudListItem from './SolicitudListItem';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: 14,
        color: 'white',
        margin: theme.spacing(2, 3),
        textAlign: 'center',
    },
    diaVacio: {
        color: '#8F95AF',
    },
    vacaciones: {
        '&&& button': {
            backgroundColor: '#00B2FF',
            color: 'white',
            opacity: 0.5,
        },
    },
    rechazada: {
        '&&& button': {
            backgroundColor: '#E9504F',
            color: 'white',
            opacity: 0.5,
        },
    },
    pendiente: {
        '&&& button': {
            backgroundColor: '#818CAE',
            color: 'white',
            opacity: 0.5,
        },
    },
    ausencia: {
        '&&& button': {
            backgroundColor: '#064DB7',
            color: 'white',
            opacity: 0.5,
        },
    },
    selected: {
        '&&& button': {
            opacity: 1,
        },
    },
    noLaborable: {
        '& button': {
            backgroundColor: '#EEEEEE',
        },
    },
    jornadaBullet: {
        backgroundColor: '#8F95AF',
        width: 12,
        height: 12,
        borderRadius: 4,
        marginRight: theme.spacing(1),
    },
    jornadaCompletaBullet: {
        backgroundColor: '#2CBD96',
    },
    jornadaParcialBullet: {
        backgroundColor: '#F3AC3D',
    },
    noLaborableBullet: {
        backgroundColor: 'transparent',
        border: '1px solid #EA5759',
        boxSizing: 'border-box',
    },
    infoPaper: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: theme.spacing(2),
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            color: '#213061',
        },
        '&>div+div': {
            marginTop: theme.spacing(1.5),
        },
        marginBottom: theme.spacing(3),
    },
}));

const materialTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            body2: {
                fontFamily: 'inherit',
                fontWeight: 'inherit',
            },
        },
        MuiButtonBase: {
            root: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 13,
            },
        },
        MuiPickersBasePicker: {
            container: {
                alignItems: 'center',
            },
            pickerView: {
                minHeight: 340,
                justifyContent: 'flex-start',
                maxWidth: 'none',
                width: '100%',
            },
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                // flex: 1,
                backgroundColor: 'transparent',
                overflow: 'initial',
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: '#213061',
                backgroundColor: 'transparent',
            },
            transitionContainer: {
                textTransform: 'capitalize',
                color: '#213061',
            },
            dayLabel: {
                color: '#213061',
                textTransform: 'uppercase',
                fontWeight: 600,
            },
            daysHeader: {
                justifyContent: 'space-between',
            },
        },
        MuiPickersCalendar: {
            week: {
                justifyContent: 'space-between',
            },
        },
        MuiPickersDay: {
            day: {
                color: 'inherit',
                fontWeight: 'normal',
                borderRadius: 8,
                margin: 2,
            },
            daySelected: {
                backgroundColor: 'transparent',
                color: 'inherit',
                fontWeight: 'normal',
            },
            current: {
                fontWeight: 700,
                backgroundColor: 'inherit',
                color: '#45486E',
                border: '2px solid #45486E',
                '&>span:first-of-type': {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '&>span:first-of-type::before': {
                    content: '"HOY"',
                    fontSize: 8,
                    marginTop: 3,
                    marginBottom: -3,
                },
            },
        },
    },
});

export const CalendarioView = ({ currentMonth, selectedDate: selectedDateIso }) => {
    const selectedDate = useMemo(() => new Date(selectedDateIso), [selectedDateIso]);

    const [noLaborables, setNoLaborables] = useState([]);
    const [solicitudesDias, setSolicitudesDias] = useState({});

    const history = useHistory();
    const classes = useStyles();

    const currentYear = currentMonth.getFullYear();

    useEffect(() => {
        operariosProvider.getNoLaborablesYear(currentYear).then(setNoLaborables);
    }, [currentYear]);

    useEffect(() => {
        solicitudesProvider
            .getByYearMonth(currentMonth.getFullYear(), currentMonth.getMonth() + 1)
            .then((solicitudes) => {
                const dias = {};
                solicitudes.forEach((solicitud) => {
                    const fechaInicio = new Date(solicitud.fecha_inicio);
                    fechaInicio.setHours(0, 0, 0, 0);
                    const fechaFin = new Date(solicitud.fecha_fin);
                    fechaFin.setHours(23, 59, 59, 0);

                    const selected = isWithinInterval(selectedDate, { start: fechaInicio, end: fechaFin });

                    // eslint-disable-next-line no-unmodified-loop-condition
                    for (let day = fechaInicio; day <= fechaFin; day.setDate(day.getDate() + 1)) {
                        const isoDay = formatISODate(day);
                        dias[isoDay] = { ...convertDates(solicitud), selected };
                    }
                });

                setSolicitudesDias(dias);
            });
    }, [currentMonth, selectedDate]);

    const selectedSolicitud = solicitudesDias[selectedDateIso];

    return (
        <React.Fragment>
            <PageHeader
                title='Vacaciones y ausencias'
                startButton={
                    <IconButton onClick={() => history.push('/solicitudes')}>
                        <ArrowBackIcon style={{ color: 'white' }} />
                    </IconButton>
                }
            ></PageHeader>
            <PageBody>
                <ThemeProvider theme={materialTheme}>
                    <DatePicker
                        disableToolbar={true}
                        autoOk
                        openTo='date'
                        variant='static'
                        value={currentMonth}
                        onChange={(ev) => {
                            history.replace(
                                `/solicitudes/calendario/${currentMonth.getFullYear()}/${
                                    currentMonth.getMonth() + 1
                                }/${formatISODate(ev)}`,
                            );
                        }}
                        onMonthChange={(date) =>
                            history.replace(`/solicitudes/calendario/${date.getFullYear()}/${date.getMonth() + 1}`)
                        }
                        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                            const isoDay = formatISODate(day);
                            const dia = solicitudesDias[isoDay];

                            let diaClass = classes.diaVacio;

                            if (dia) {
                                diaClass =
                                    dia.estado === 'APROBADA'
                                        ? classes[dia.tipo.toLowerCase()]
                                        : classes[dia.estado.toLowerCase()];
                            }

                            return (
                                <span
                                    className={classNames(diaClass, {
                                        [classes.noLaborable]: noLaborables.includes(isoDay),
                                        [classes.selected]: dia && dia.selected,
                                    })}
                                >
                                    {dayComponent}
                                </span>
                            );
                        }}
                    />
                </ThemeProvider>

                <LeyendaCalendario
                    items={[
                        {
                            color: '#00B2FF',
                            label: 'Vacaciones',
                        },
                        {
                            color: '#064DB7',
                            label: 'Ausencia',
                        },
                        {
                            color: '#818CAE',
                            label: 'Solicitud pendiente',
                        },
                        {
                            color: '#E9504F',
                            label: 'Solicitud rechazada',
                        },
                        {
                            color: '#DFE2EF',
                            label: 'No laborable',
                        },
                    ]}
                />

                {selectedSolicitud && <SolicitudListItem solicitud={selectedSolicitud} expanded />}
            </PageBody>
            {/* <Navigation /> */}
        </React.Fragment>
    );
};

CalendarioView.propTypes = {
    currentMonth: PropTypes.any,
    selectedDate: PropTypes.any,
};
